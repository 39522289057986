import './404.scss'
import React from 'react'
import { graphql } from 'gatsby'
import Subnavigation from '../components/subnavigation'
import Footer from '../components/footer'
import { chevronLeft } from '@teliads/icons'
import { BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { TeliaGrid, TeliaRow, TeliaCol, TeliaTextSpacing, TeliaLink, TeliaIcon, TeliaP, TeliaHeading } from '@teliads/components/react'

const NotFoundPage = ({ data, location }) => {
  const pageData = data.contentfulNotFoundPage

  if (!pageData) {
    return null
  }

  const subnavigation = pageData.subnavigation
  const text = pageData.text
  const backLinkText = pageData.backLinkText
  const footer = pageData.footer

  return (
    <>
      {subnavigation && (
        <Subnavigation {...subnavigation} location={location} />
      )}

      <div className='cms-page'>
        <TeliaGrid>
          <TeliaRow>
            <TeliaCol width='12'>
              <div className='cms-notfound__content'>
                <TeliaTextSpacing>
                  {renderRichText({
                    raw: text.raw
                  }, richTextOptions)}
                </TeliaTextSpacing>

                {backLinkText && (
                  <TeliaLink variant='standalone' className='cms-notfound__back-link' onClick={() => { window.history.back() }}>
                    <TeliaIcon slot='left' svg={chevronLeft.svg} size='sm' />
                    {backLinkText}
                  </TeliaLink>
                )}
              </div>
            </TeliaCol>
          </TeliaRow>
        </TeliaGrid>

        {footer && (
          <Footer {...footer} />
        )}
      </div>
    </>
  )
}

const richTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <TeliaP>{children}</TeliaP>,
    [BLOCKS.HEADING_1]: (node, children) => <TeliaHeading className='cms-notfound__title' tag='h1' variant='display-100'>{children}</TeliaHeading>
  }
}

// No identifier for this single query as there should only be one NotFoundPage in Contentful
export const pageQuery = graphql`
  query NotFoundQuery {
    contentfulNotFoundPage {
      id
      subnavigation {
        ...Subnavigation
      }
      text {
        raw
      }
      backLinkText
      footer {
        ...Footer
      }
    }
  }
`

export default NotFoundPage
